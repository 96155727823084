<template>
  <div
    class="w-block__post-featured"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- post-featured block -->
    <v-container v-if="isHomeActive" class="pa-0">
      <v-sheet
        ref="sheet"
        color="#ecf3dd"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div
          class="text-alternate"
          :class="{
            'body-2 font-weight-normal': $voicer.isDesktopOrTablet,
            'body-2': $voicer.isMobile,
          }"
        >
          <div class="pl-2 pr-2 pt-1">
            Maxi Zoo vos offre les meilleures playlists conçues par des
            vétérinaires pour détendre vos animaux
          </div>
          <v-btn
            ref="btn"
            color="#FFB300"
            rounded
            depressed
            class="ma-2 white--text"
            @click="handleOpen('https://sdz.sh/cGXPv7')"
          >
            <v-icon color="#ffffff" class="mr-2"> mdi-rabbit </v-icon>
            Tout doux lapinou
          </v-btn>
          <v-btn
            ref="btn"
            color="#819F17"
            rounded
            depressed
            class="ma-2 white--text"
            @click="handleOpen('https://sdz.sh/Cz1neF')"
          >
            <v-icon color="#ffffff" class="mr-2"> mdi-dog </v-icon>
            Tout doux toutou
          </v-btn>
          <v-btn
            ref="btn"
            color="#ED6E3C"
            rounded
            depressed
            class="ma-2 white--text"
            @click="handleOpen('https://sdz.sh/7bzKZe')"
          >
            <v-icon color="#ffffff" class="mr-2"> mdi-cat </v-icon>
            Tout doux matou
          </v-btn>
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      showFeedbackModal: false,
    }
  },
  computed: {
    isHomeActive() {
      return this.$route.name === 'index'
    },
  },
  methods: {
    handleOpen(url) {
      window.open(url)
    },
    isUniverse(id) {
      return (
        this.$route.name === 'universe' && this.$route.params.universe === id
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.w-block__post-featured {
  justify-content: center;
  text-align: center;
  font-family: Flame !important;
}
.custom-color {
  color: #502314;
}
.subtitle-1 {
  font-weight: bold;
  color: #ffffff;
}
.body-2 {
  text-align: center;
}
</style>
