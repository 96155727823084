<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="isHomeActive" class="pa-0">
      <v-sheet
        ref="sheet"
        color="primary"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div
          class="text-alternate"
          :class="{
            'body-2 font-weight-normal': $voicer.isDesktopOrTablet,
            'body-2': $voicer.isMobile,
          }"
        >
          <div class="pl-2 pr-2 pt-1 white--text">
            Bienvenue sur le site de votre nouveau podcast !
            <br />
            Rendez-vous une fois par mois sur Happy Radio le Mag, votre media
            100% dédié aux équipes de Maxi Zoo
          </div>
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      showFeedbackModal: false,
    }
  },
  computed: {
    isHomeActive() {
      return this.$route.name === 'index'
    },
  },
}
</script>
<style lang="scss" scoped>
.w-block__pre-heading {
  justify-content: center;
  text-align: center;
  font-weight: bold;

  .v-sheet {
    text-align: center;
    border-radius: 8px;
  }
  .v-sheet__desktop {
    & > div {
      align-items: center;

      .v-btn {
        border-radius: 4px;
      }
    }
  }
}
.body-2 {
  text-align: center;
}
.white--text {
  a {
    color: white;
    text-decoration: underline;
  }
}
</style>
